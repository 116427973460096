<template>
  <div class="app-container admin-look-up">
    <el-tabs
      v-model="activeTabName"
      type="card"
      @tab-remove="removeEditItemTab"
    >
      <el-tab-pane :label="$t('lookup.classifyList')" :name="classifyListTab">
        <el-row class="filter-line">
          <el-col :span="4">
            <el-button
              icon="el-icon-plus"
              size="small"
              type="primary"
              @click="addClassify"
              v-ruge-permission="$ALLPERMISSIONS.manageLookup"
            >
              {{ $t("lookup.addClassify") }}
            </el-button>
          </el-col>
          <el-col :span="20" align="right">
            <div>
              <TenantSelect
                style="width: 200px; margin-right: 10px"
                size="small"
                :clearable="false"
                @change="tenantChange"
                v-model="classify.listQuery.tenantId"
              />
              <el-input
                :placeholder="$t('commons.searchPhrase')"
                v-model="classify.listQuery.searchPhrase"
                clearable
                maxlength="50"
                size="small"
                style="width: 200px; margin-right: 10px"
                class="filter-item"
                @keyup.enter.native="handleClassifyFilter"
              />
              <el-button
                :loading="classify.listLoading"
                size="small"
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                @click="handleClassifyFilter"
              >
                {{ $t("commons.search") }}
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-table
          v-loading="classify.listLoading"
          :data="classify.list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          stripe
        >
          <el-table-column
            fixed
            type="index"
            align="center"
            :label="$t('commons.index')"
            width="95"
          >
          </el-table-column>
          <el-table-column prop="tenantName" :label="$t('lookup.tenantName')">
          </el-table-column>
          <el-table-column
            prop="classifyCode"
            :label="$t('lookup.classifyCode')"
          >
          </el-table-column>
          <el-table-column
            prop="classifyName"
            :label="$t('lookup.classifyName')"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  maxlength="50"
                  :show-word-limit="true"
                  v-model="scope.row.classifyName"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.classifyName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="classifyDesc"
            :label="$t('lookup.classifyDesc')"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.classifyDesc"
                  maxlength="160"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.classifyDesc }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            :label="$t('commons.actions')"
            width="150"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-tooltip
                  :content="$t('commons.save')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    type="success"
                    size="mini"
                    circle
                    icon="el-icon-check"
                    :loading="scope.row.processing"
                    @click="handleClassifyUpdate(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.cancel')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-close"
                    circle
                    type="warning"
                    :disabled="scope.row.processing"
                    @click="cancelClassifyEdit(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
              </template>
              <template v-else>
                <el-tooltip
                  :content="$t('commons.edit')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="success"
                    circle
                    :disabled="scope.row.processing"
                    @click="scope.row.edit = !scope.row.edit"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('lookup.editItem')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-tickets"
                    circle
                    @click="editItem(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.delete')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    :loading="scope.row.processing"
                    @click="handleClassifyDelete(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="classify.total > 0"
          :total="classify.total"
          :page.sync="classify.listQuery.current"
          :limit.sync="classify.listQuery.rowCount"
          @pagination="getClassifyList"
        />
        <!-- 添加类别对话框 -->
        <el-dialog
          :visible.sync="classify.dialogVisible"
          :before-close="cancelClassifyAdd"
          width="30%"
          :close-on-click-modal="false"
        >
          <template slot="title">
            <title-icon />{{ $t("lookup.addClassify") }}
          </template>
          <el-form
            label-position="top"
            :model="classify.form"
            ref="classifyForm"
            :rules="classify.formRules"
          >
            <el-form-item
              :label="$t('lookup.classifyCode')"
              prop="classifyCode"
            >
              <el-input
                v-model="classify.form.classifyCode"
                maxlength="16"
                :show-word-limit="true"
                autocomplete="off"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('lookup.classifyName')"
              prop="classifyName"
            >
              <el-input
                v-model="classify.form.classifyName"
                maxlength="50"
                :show-word-limit="true"
                autocomplete="off"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('lookup.classifyDesc')"
              prop="classifyDesc"
            >
              <el-input
                type="textarea"
                v-model="classify.form.classifyDesc"
                maxlength="160"
                :show-word-limit="true"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="cancelClassifyAdd">{{
              $t("commons.cancel")
            }}</el-button>
            <el-button
              type="primary"
              @click="handleClassifyCreate"
              v-loading.fullscreen.lock="classify.createLoading"
              >{{ $t("commons.save") }}
            </el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane
        v-if="editableTab"
        :key="editableTab.name"
        :label="editableTab.title"
        :name="editableTab.name"
        closable
      >
        <el-row>
          <el-col :span="6">
            <el-tag>{{ item.classifyCode }}/{{ item.classifyName }}</el-tag>
          </el-col>
          <el-col :span="6" :offset="12">
            <div class="filter-container" align="right">
              <el-button
                :disabled="item.multipleSelection.length === 0"
                :loading="item.listLoading"
                icon="el-icon-delete"
                type="danger"
                size="small"
                @click="handleItemBatchDelete"
                v-ruge-permission="$ALLPERMISSIONS.manageLookup"
              >
                {{ $t("commons.batchDelete") }}
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-table
          v-loading="item.listLoading"
          :data="item.list"
          row-key="itemId"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column fixed type="selection" align="center" width="50">
          </el-table-column>
          <el-table-column :label="$t('lookup.itemCode')" width="220">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.itemCode"
                  maxlength="50"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.itemCode }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lookup.itemName')" width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.itemName"
                  maxlength="50"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.itemName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lookup.attr1')" width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.attr1"
                  maxlength="33"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.attr1 }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lookup.attr2')" width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.attr2"
                  maxlength="33"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.attr2 }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lookup.language')" width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-select
                  v-model="scope.row.language"
                  :placeholder="$t('commons.selectPlease')"
                  size="small"
                >
                  <el-option
                    v-for="(item, key) in supportLang"
                    :key="key"
                    :label="item"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </template>
              <span v-else>{{ supportLang[scope.row.language] }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lookup.itemDesc')">
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-input
                  v-model="scope.row.description"
                  maxlength="160"
                  :show-word-limit="true"
                  size="small"
                  clearable
                  :placeholder="$t('commons.pleaseInput')"
                />
              </template>
              <span v-else>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('commons.actions')"
            width="150"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.edit">
                <el-tooltip
                  :content="$t('commons.save')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    :loading="scope.row.processing"
                    type="success"
                    size="mini"
                    icon="el-icon-check"
                    @click="handleItemSave(scope.row)"
                    circle
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('commons.cancel')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    :disabled="scope.row.processing"
                    size="mini"
                    icon="el-icon-close"
                    type="warning"
                    @click="cancelItemEdit(scope.row)"
                    circle
                  >
                  </el-button>
                </el-tooltip>
              </template>
              <template v-else>
                <el-tooltip
                  :content="$t('commons.edit')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="success"
                    circle
                    :disabled="scope.row.processing"
                    @click="
                      scope.row.edit = !scope.row.edit;
                      item.editTotal++;
                    "
                  >
                  </el-button>
                </el-tooltip>
                <template v-if="item.editTotal === 0">
                  <el-tooltip
                    :content="$t('commons.dragSort')"
                    placement="bottom"
                    :open-delay="openDelay"
                  >
                    <el-button
                      type="info"
                      icon="el-icon-rank"
                      class="drag-handler"
                      size="mini"
                      circle
                    ></el-button>
                  </el-tooltip>
                </template>
                <el-tooltip
                  :content="$t('commons.delete')"
                  placement="bottom"
                  :open-delay="openDelay"
                >
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    :loading="scope.row.processing"
                    @click="handleItemDelete(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          icon="el-icon-plus"
          class="el-table-add-row"
          type="primary"
          @click="addItem"
          v-ruge-permission="$ALLPERMISSIONS.manageLookup"
        >
          {{ $t("commons.add") }}
        </el-button>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  getClassifyList,
  createClassify,
  updateClassify,
  deleteClassify,
  getItemList,
  deleteItem,
  addItem,
  updateItem,
  changeIndex4Item,
} from "@/api/ruge/lookup/lookup";
import TenantSelect from "@/components/TenantSelect";
import Pagination from "@/components/Pagination";
import Sortable from "sortablejs";
import { envInfo } from "@/constants/envInfo";

// 声明tab名称
const tabName = {
  // 类别列表Tab
  classifyListTab: "classifyListTab",
  // 项编辑Tab
  editItemTab: "editItemTab",
};

export default {
  name: "Lookup",
  components: { Pagination, TenantSelect },
  data() {
    return {
      // Tooltip显示延迟时间(毫秒)
      openDelay: 1000,
      // tab组件配置
      // 当前tab名称
      activeTabName: tabName.classifyListTab,
      // 动态添加的tab
      editableTab: undefined,
      // 类别相关参数
      classify: {
        // 列表参数
        list: [],
        total: 0,
        listLoading: true,
        listQuery: {
          current: 1,
          rowCount: 10,
          searchPhrase: undefined,
          tenantId: -1,
        },
        // 新建对话框是否可见，默认不可见
        dialogVisible: false,
        // 添加类别加载状态
        createLoading: false,
        form: {
          classifyCode: null,
          classifyName: null,
          classifyDesc: null,
        },
        // 表单校验规则
        formRules: {
          classifyCode: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
      // 项相关参数
      item: {
        classifyCode: null,
        classifyName: null,
        // 列表参数
        list: [],
        listLoading: true,
        sortable: undefined,
        // 编辑状态行的数量，用于有行在编辑状态时，不允许拖拽
        editTotal: 0,
        // 勾选的行
        multipleSelection: [],
      },
    };
  },
  created() {
    this.getClassifyList();
  },
  computed: {
    classifyListTab() {
      return tabName.classifyListTab;
    },
    tenantId() {
      this.classify.listQuery.tenantId = this.$store.getters.currentTenant;
    },
    supportLang() {
      return envInfo.supportLang;
    },
  },
  methods: {
    tenantChange(tenantId) {
      this.getClassifyList(tenantId);
    },
    getClassifyList() {
      this.classify.listLoading = true;
      getClassifyList(this.classify.listQuery)
        .then((response) => {
          this.classify.list = response.rows.map((v) => {
            // 编辑状态，用于行内编辑
            this.$set(v, "edit", false);
            // 数据处理状态
            this.$set(v, "processing", false);
            // 保存原有值，用于取消编辑
            this.setOriginal4Classify(v);
            return v;
          });
          this.classify.total = response.total;
          this.classify.listLoading = false;
        })
        .catch((error) => {
          this.classify.listLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    // 查询类别
    handleClassifyFilter() {
      this.classify.listQuery.current = 1;
      this.getClassifyList();
    },
    // 删除类别
    handleClassifyDelete(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          // 调用服务
          deleteClassify(row)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              // 刷新列表
              this.getClassifyList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 更新类别
    handleClassifyUpdate(row) {
      // 锁定数据
      row.processing = true;
      // 调用服务
      updateClassify(row)
        .then(() => {
          // 成功后解锁数据
          row.processing = false;
          row.edit = false;
          // 保存原有值，用于取消编辑
          this.setOriginal4Classify(row);
          // 成功提示
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          row.processing = false;
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    // 添加类别
    handleClassifyCreate() {
      this.$refs.classifyForm.validate((valid) => {
        if (valid) {
          this.classify.createLoading = true;
          // 调用服务
          createClassify({
            ...this.classify.form,
            ...{
              tenantId: this.classify.listQuery.tenantId,
            },
          })
            .then(() => {
              // 成功后重置form,并关闭弹框和遮罩层
              this.classify.dialogVisible = false;
              this.$refs.classifyForm.resetFields();
              this.classify.createLoading = false;
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              // 刷新列表
              this.getClassifyList();
            })
            .catch((error) => {
              this.classify.createLoading = false;
              console.log(`添加失败，原因 => ${error}`);
            });
        } else {
          console.log("error handleClassifyCreate!!");
          return false;
        }
      });
    },
    // 添加类别
    addClassify() {
      this.classify.dialogVisible = true;
    },
    // 取消添加
    cancelClassifyAdd() {
      this.classify.dialogVisible = false;
      this.$refs.classifyForm.resetFields();
    },
    // 取消编辑
    cancelClassifyEdit(row) {
      this.restoreOriginal4Classify(row);
      row.edit = false;
    },
    // 打开项Tab
    editItem(row) {
      this.editableTab = {
        title: this.$t("lookup.itemList"),
        name: tabName.editItemTab,
      };
      this.item.classifyCode = row.classifyCode;
      this.item.classifyName = row.classifyName;
      this.item.tenantId = row.tenantId;
      // 显示item tab
      this.activeTabName = tabName.editItemTab;
      this.zeroingItemEditTotal();
      // 刷新列表
      this.getItemList();
    },
    // 关闭项Tab
    removeEditItemTab() {
      this.activeTabName = tabName.classifyListTab;
      this.item.sortable = undefined;
      this.editableTab = undefined;
      this.zeroingItemEditTotal();
    },
    // 归零编辑状态行的数量，防止编辑后重新打开不显示拖拽图标问题
    zeroingItemEditTotal() {
      this.item.editTotal = 0;
    },
    getItemList() {
      this.item.listLoading = true;
      getItemList({
        classifyCode: this.item.classifyCode,
        tenantId: this.item.tenantId,
      })
        .then((response) => {
          // 由于当没有item时，后台会返回一个空行，为了显示效果，通过filter将空行过滤掉
          this.item.list = response.lookupItemList
            .filter((x) => x.itemId)
            .map((v) => {
              // 编辑状态，用于行内编辑
              this.$set(v, "edit", false);
              // 数据处理状态
              this.$set(v, "processing", false);
              // 保存原有值，用于取消编辑
              this.setOriginal4Item(v);
              return v;
            });
          this.item.listLoading = false;
          this.$nextTick(() => {
            this.setSort();
          });
        })
        .catch((error) => {
          this.item.listLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    setSort() {
      if (!this.item.sortable) {
        // 防止重复处理DOM
        const el = document.querySelectorAll(
          "#pane-editItemTab .el-table__body-wrapper > table > tbody"
        )[0];
        this.item.sortable = Sortable.create(el, {
          handle: ".drag-handler", // 拖动列表中特定的项,只有class='drag-handler'的对象才可以拖拽
          ghostClass: "sortable-ghost", // Class name for the drop placeholder,
          setData: function (dataTransfer /*,dragEl*/) {
            dataTransfer.setData("Text", "");
            // to avoid Firefox bug
            // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          },
          onEnd: (evt) => {
            let { oldIndex, newIndex } = evt;
            if (oldIndex === newIndex) {
              return;
            }
            let fromRow = this.item.list[oldIndex];
            let toRow = this.item.list[newIndex];
            fromRow.toIndex = toRow.itemIndex;
            fromRow.tenantId = this.item.tenantId;
            this.item.listLoading = true;
            // 调用服务更新itemIndex,更新成功后刷新table
            changeIndex4Item(fromRow)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.saveSuccess"),
                });
                // 刷新列表
                this.getItemList();
              })
              .catch((error) => {
                // 更新失败还原顺序
                fromRow.toIndex = undefined;
                this.item.listLoading = false;
                console.log(`更改顺序失败，原因 => ${error}`);
              });
          },
        });
      }
    },
    // 添加item
    addItem() {
      let newItem = {
        // 编辑状态
        edit: true,
        // 锁定不可拖拽状态
        // fixed: true,
        classifyCode: this.item.classifyCode,
      };
      // 在末尾添加一行
      this.item.list = [...this.item.list, newItem];
      this.item.editTotal++;
    },
    // 取消编辑
    cancelItemEdit(row) {
      // 新增未保存的数据，就直接删除了
      if (!row.itemId) {
        const index = this.item.list.indexOf(row);
        this.item.list.splice(index, 1);
      } else {
        // 还原值
        this.restoreOriginal4Item(row);
      }
      row.edit = false;
      this.item.editTotal--;
    },
    // 勾选行时触发
    handleSelectionChange(val) {
      this.item.multipleSelection = val;
    },
    // 删除项
    handleItemDelete(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 锁定数据
          row.processing = true;
          let params = {
            classifyCode: this.item.classifyCode,
            classifyName: this.item.classifyName,
            // 列表参数
            lookupItemList: [row],
            tenantId: this.item.tenantId,
          };
          // 调用服务
          deleteItem(params)
            .then(() => {
              // 成功后关闭loading
              row.processing = false;
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              // 刷新列表
              this.getItemList();
            })
            .catch(() => {
              row.processing = false;
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 批量删除项
    handleItemBatchDelete() {
      if (this.item.multipleSelection.length > 0) {
        // 删除选中的行
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            // 锁定数据
            this.item.listLoading = true;
            let params = {
              classifyCode: this.item.classifyCode,
              classifyName: this.item.classifyName,
              // 列表参数
              lookupItemList: this.item.multipleSelection,
              tenantId: this.item.tenantId,
            };
            // 调用服务
            deleteItem(params)
              .then(() => {
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.deleteSuccess"),
                });
                // 刷新列表
                this.getItemList();
              })
              .catch(() => {
                this.item.listLoading = false;
              });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    // 保存项
    handleItemSave(row) {
      const params = {
        ...row,
        ...{
          tenantId: this.item.tenantId,
        },
      };
      // 锁定数据
      row.processing = true;
      if (!row.itemId) {
        // 调用服务新增
        addItem(params)
          .then((response) => {
            // 成功后解锁数据
            row.processing = false;
            row.edit = false;
            this.item.editTotal--;
            // 回填ID，防止重复添加
            row.itemId = response.itemId;
            row.itemIndex = response.itemIndex;
            // 保存原有值，用于取消编辑
            this.setOriginal4Item(row);
            // 成功提示
            this.$message({
              type: "success",
              message: this.$t("message.saveSuccess"),
            });
          })
          .catch((error) => {
            row.processing = false;
            console.log(`保存失败，原因 => ${error}`);
          });
      } else {
        // 调用服务更新
        updateItem(params)
          .then(() => {
            // 成功后解锁数据
            row.processing = false;
            row.edit = false;
            this.item.editTotal--;
            // 保存原有值，用于取消编辑
            this.setOriginal4Item(row);
            // 成功提示
            this.$message({
              type: "success",
              message: this.$t("message.saveSuccess"),
            });
          })
          .catch((error) => {
            row.processing = false;
            console.log(`保存失败，原因 => ${error}`);
          });
      }
    },
    // 保存原有值，用于取消编辑
    setOriginal4Classify(item) {
      item.originalClassifyName = item.classifyName || null;
      item.originalClassifyDesc = item.classifyDesc || null;
    },
    // 还原原有值，用于取消编辑
    restoreOriginal4Classify(item) {
      item.classifyName = item.originalClassifyName;
      item.classifyDesc = item.originalClassifyDesc;
    },
    // 保存原有值，用于取消编辑
    setOriginal4Item(item) {
      item.originalItemCode = item.itemCode || null;
      item.originalItemName = item.itemName || null;
      item.originalAttr1 = item.attr1 || null;
      item.originalAttr2 = item.attr2 || null;
      item.originalLanguage = item.language || null;
      item.originalDescription = item.description || null;
    },
    // 还原原有值，用于取消编辑
    restoreOriginal4Item(item) {
      item.itemCode = item.originalItemCode;
      item.itemName = item.originalItemName;
      item.attr1 = item.originalAttr1;
      item.attr2 = item.originalAttr2;
      item.language = item.originalLanguage;
      item.description = item.originalDescription;
    },
  },
};
</script>
<style lang="less" scoped >
.admin-look-up {
  .filter-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}

.drag-handler {
  cursor: move;
}

.el-table-add-row {
  margin-top: 10px;
  width: 100%;
  justify-content: center;
}
</style>
